@if (cfg) {

	<shared-bottom-sheet [body]="body"/>

	<ng-template #body>

		<div class="wrapper">

			<div class="star">
				<i class="fas fa-star {{cfg.star}}"></i>
			</div>

			<div class="title" [innerHTML]="cfg.title | translate : cfg.titleParams"></div>

			<div class="subtitle" [innerHTML]="cfg.message | translate : cfg.messageParams"></div>

			<button class="btn btn-primary" (click)="confirm()">{{ cfg.confirmText | translate }}</button>

			@if (cfg.cancelText) {
				<button class="btn btn-outline-primary" (click)="hide()">{{ cfg.cancelText | translate }}</button>
			}

		</div>

	</ng-template>

}
