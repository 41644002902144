import {Component} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'

import {EduService} from '@ui/services/edu.service'

import {StarGainModalCfg} from '@wv/schemes/ui/star-gain-modal-cfg'

@Component({
  templateUrl: './star-gain-modal.component.html',
  styleUrls: ['./star-gain-modal.component.scss'],
})
export class StarGainModalComponent {

  cfg: StarGainModalCfg

  constructor(
    private bsModalRef: BsModalRef,
    private eduService: EduService,
  ) {
  }

  hide(): void {
    this.bsModalRef.hide()
  }

  confirm(): void {
    if (this.cfg.readParams) {
      const {masterSubjectId, moduleId, starInfoId} = this.cfg.readParams
      this.eduService.readStarInfo(masterSubjectId, moduleId, starInfoId).subscribe()
    }

    this.cfg.onConfirm?.()
    this.hide()
  }
}
